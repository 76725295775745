// menu style

.logoContainer {
  width: 52px;
  height: 52px;
  // border: 1px solid;
  padding: 0.625rem;
  margin: 0 1.25rem;
  background: linear-gradient(
    213.23deg,
    rgba(0, 0, 0, 0.1) 28.44%,
    rgba(255, 255, 255, 0) 72.58%
  );
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.logoContainer::before {
  content: "";
  position: absolute;
  backdrop-filter: blur(2px);
  inset: 0;
  border-radius: 12px;
  padding: 1.5px;
  mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
  mask-composite: exclude;
  background: linear-gradient(
    223.71deg,
    rgba(255, 255, 255, 0.5) 7.24%,
    rgba(255, 255, 255, 0.2) 24.31%,
    rgba(255, 255, 255, 0.05) 100%
  );
}

.playgroudMenuContainer .divider {
  display: block;
  margin: 0 1.25rem;
}

.itemContainer {
  width: 113px;
  // padding: 1.25rem;
  // height: 57px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  color: antiquewhite;
  position: relative;
}

@media (max-width: 992px) {
  .itemContainer {
    padding: 1.25rem 0;
  }
}

.itemContainer.is-active .itemText {
  color: #ffffff;
}

.itemContainer::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0px 20px 20px 0px;
  background: transparent;
  transition: all 0.4s ease-in-out;
}

.itemContainer.is-active::before {
  background: #d11ce9;
}

@media (max-width: 992px) {
  .itemContainer::before {
    width: 100%;
    height: 4px;
    top: unset;
    bottom: 0;
  }
}

.itemText {
  font-family: "Instrument Sans";
  font-size: 11px;
  font-weight: 500;
  line-height: 13.42px;
  text-align: center;
  color: #9d9d9d;
}

// text container

.ellipse-3 {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  position: absolute;
  // top: 0;
  z-index: 1;
  // left: 0;
  background: rgba(209, 28, 233, 1);
  filter: blur(220px);
  // width: 100%;
  // height: 100%;
  // max-width: 100vw;
  // max-height: 100vh;
}

.headerLogoStyle {
  // width: 161px;
  // height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.25rem;
  position: relative;
}

.headerLogoStyle::before {
  content: "";
  position: absolute;
  backdrop-filter: blur(2px);
  inset: 0;
  border-radius: 12px;
  padding: 1.2px;
  mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
  mask-composite: exclude;
  background: linear-gradient(
    223.71deg,
    rgba(255, 255, 255, 0.5) 7.24%,
    rgba(255, 255, 255, 0.2) 24.31%,
    rgba(255, 255, 255, 0) 100%
  );
}

.subHeaderText {
  font-family: "Playfair Display";
  // font-size: 45px;
  // font-style: italic;
  // font-weight: 600;
  // line-height: 60px;
  background-image: linear-gradient(93.32deg, #ffffff 0.24%, #b4b4b4 99.76%);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  margin-bottom: 1.25rem;
}

.subHeading {
  font-family: "Instrument Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #9a9a9a;
  width: 100%;
  max-width: 475px;
  margin-bottom: 2.5rem;
}

@media (max-width: 453px) {
  .subHeading {
    font-size: 14px;
  }
}

// .text-form {
// width: 100%;
// max-width: 622px;
// display: flex;
// flex-direction: column;
// align-items: center;
// box-shadow: 0px 16px 48px 20px #D323E71A;
// }

.inputstyle {
  width: 100%;
  // height: 48px;
  padding: 12px 16px;
  border-radius: 100px;
  border: 1px solid #ffffff1a;
  display: flex;
  align-items: start;
  gap: 0.75rem;
  background-color: transparent;
  margin-bottom: 1.25rem;
}

.inputstyle .search-input {
  outline: none;
  max-height: 200px;
  overflow: auto;
  flex: 1 0 0;
  height: 100%;
  font-family: "Instrument Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: white;
  resize: none;
}

.inputstyle .search-input.placeholder {
  color: rgba(114, 114, 114, 1);
}

.generate-btn {
  height: unset;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: #d11ce9;
  box-shadow: 0px 16px 48px 20px #d323e71a;
  font-family: "Instrument Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  transition: all 0.3s ease-in-out;
}

.generate-btn:hover {
  background: transparent;
  // border-color: #D11CE9;
}

.generate-btn.is-generating {
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 16px 48px 20px rgba(211, 35, 231, 0.1);
  background-color: transparent;
}

.generate-btn.is-generating .line-1 {
  backdrop-filter: blur(12px);
  opacity: 0.5;
  top: -1px;
  left: -1px;
}

.inputstyle:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
}
// .inputstyle .search-input .placeholder {
//   color: rgba(114, 114, 114, 1);
//   font-weight: 500;
// }

.inputstylefocus {
  height: 48px;
  background: linear-gradient(135deg, #0a0a0a 20%, #2e002e 50%, #0a0a0a 80%);
  color: #727272;
  border: 1px solid #2e002e;
  border-radius: 20px;
  margin-top: 1rem;
}

.button_Style {
  height: 48px;
  border: 1px solid transparent;
  border-bottom: 1px solid;
  border-radius: 20px;
  border-image-slice: 1;
  box-shadow: 0px 16px 48px 20px #d323e71a;
  background: #d11ce9;
  border-color: #ffffff1a;
  border-width: 2;
  color: white;
  font-size: 16px;
  font-weight: 500;
}

// rotate container
.rotateSignContainer {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  padding: 0 1.125rem;
}

//upload container

.uploadImageContainer {
  width: 100%;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.uploadHeaderText {
  font-family: "Instrument Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.52px;
}

.uploadImageItemStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.875rem;
  padding: 1.25rem;
  border: 1px dashed #270d2f;
  border-radius: 20px;
  margin-bottom: 1.25rem;
}

// .uploadImageItemStyle .file-upload {
//   position: absolute;
//   inset: 0;
//   opacity: 0;
//   cursor: pointer;
// }

.uploadText {
  font-size: 11px;
  font-weight: 500;
  line-height: 13.42px;
  color: rgba(123, 123, 123, 1);
  text-align: center;
}

.uploadButtonContainer {
  width: 100%;
  height: 32px;
  color: white;
  background: #d11ce9;
  padding: 0.5rem 1.5rem;
  font-size: 11px;
  font-weight: 500;
  line-height: 13.42px;
  border-radius: 100px;
  box-shadow: 0px 16px 48px 20px rgba(211, 35, 231, 0.1);
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  gap: 0.63rem;
  position: relative;
  overflow: hidden;
}

.text-generate-modal .uploadButtonContainer.is-generating {
  color: white;
  opacity: 0.8;
}

.text-generate-modal .uploadButtonContainer .text-generate-icon {
  opacity: 0.8;
}

.uploadButtonContainer.is-generating {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: unset;
  color: rgba(157, 157, 157, 1);
  box-shadow: none;
  cursor: inherit;
}

.uploadButtonContainer.is-generating img {
  opacity: 0.5;
}

.uploadButtonContainer .text-generate-icon {
  width: 1rem;
  height: 1rem;
}

.upload-img-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1.25rem;
  position: relative;
}

.text-generating {
  position: absolute;
  inset: 0;
  background-color: transparent;
  // background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}

.img-generating {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-generating .loading-icon,
.text-generating .loading-icon {
  animation: rotating infinite 0.7s linear;
}

@keyframes rotating {
  to {
    transform: rotate(360deg);
  }
}

.form-generate {
  width: 100%;
}

.form-generate .form-container {
  position: relative;
  margin-bottom: 1.25rem;
}

.form-generate textarea {
  width: 100%;
  height: 112px;
  padding: 12px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: transparent;
  font-size: 11px;
  line-height: 13.42px;
  outline: none;
  color: rgba(255, 255, 255, 1);
  font-family: inherit;
  resize: none;
}

.form-generate textarea::placeholder {
  font-weight: 500;
  color: rgba(123, 123, 123, 1);
}

.form-generate.is-generating textarea {
  color: rgba(49, 49, 49, 1);
}

.cancel-generating-btn {
  width: 100%;
  padding: 0.5rem 1.5rem;
  margin-top: 0.5rem;
  font-size: 11px;
  font-weight: 500;
  line-height: 13.42px;
  border-radius: 100px;
  color: rgba(157, 157, 157, 1);
}

.imageStyle {
  max-width: 100%;
  max-height: 135px;
  object-fit: contain; // Ensures the image scales proportionally
}

.preCautionText {
  font-family: "Instrument Sans";
  font-size: 11px;
  font-weight: 400;
  line-height: 19.52px;
  color: #9d9d9d;
  width: 70%;
  text-align: center;
  width: 100%;
  margin-top: 1rem;
}
.rotateSignStyle {
  width: 2.75rem;
  height: 2.5rem;
  border-radius: 90px;
  border: 1px solid #ffffff1a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rotateValueContainer {
  padding: 0.25rem 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  border-radius: 100px;
  border: 1px solid #ffffff1a;
  align-items: center;
  font-family: "Instrument Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  background: linear-gradient(
    85.77deg,
    rgba(255, 255, 255, 0) 0.1%,
    rgba(255, 255, 255, 0.04) 99.9%
  );
}

.rotateValueContainer .value-update-btn {
  padding: 0.5rem 0.75rem;
  height: 2rem;
}

.custom .ant-collapse-item > .ant-collapse-header {
  color: #c6c6c6;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.52px;
}

.customContainer .ant-collapse-content-box {
  padding: 0px !important;
}

.playgroundFooterContainer {
  align-items: stretch;
}

.playgroundFooterContainer .collapse-container {
  width: 100%;
  // margin: 0 1.25rem;
  border-color: transparent !important;
}

.collapse-container.ant-collapse
  .ant-collapse-content
  > .ant-collapse-content-box {
  padding: unset;
}

.playgroundFooterContainer .collapse-container .ant-collapse-header {
  height: 72px;
  padding: 20px 32px;
  border-radius: 20px 20px 0px 0px !important;
  // border-bottom: 1px solid;
  background: rgba(15, 15, 15, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.playgroundFooterContainer .collapseExpand {
  display: flex;
  gap: 1.25rem;
  align-items: center;
}

.playgroundFooterContainer .collapse-container .header-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.52px;
  color: rgba(198, 198, 198, 1);
}

.collapse-container.ant-collapse .ant-collapse-content {
  border: none;
}

/* Tablet screens and smaller */
@media (max-width: 1024px) {
  .itemContainer {
    margin-top: 0rem;
  }

  // .subHeaderText {
  //   font-size: 28px;
  // }

  // .subHeading {
  //   font-size: 12px;
  //   width: 90%;
  // }
}

/* Mobile screens */
@media (max-width: 768px) {
  .itemContainer {
    margin-top: 0rem;
  }

  // .subHeaderText {
  //   font-size: 16px;
  //   margin-top: 0rem;
  // }

  // .subHeading {
  //   font-size: 8px;
  //   margin-top: 0rem;
  //   width: 80%;
  // }
}

.generated-img {
  max-width: 566px;
  max-height: 402px;
  object-fit: contain;
}