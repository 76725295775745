

.mainContianer{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 1rem;
}

.passwordstyle{
  cursor: pointer;
}

.errorStyle{
  color: red;
  font-size: 12px;
  font-weight: 600;
  margin-left: 3px;
}


.mainContianer .ant-input::placeholder {
  color: #727272!important; /* Force white color for placeholder */
  opacity: 1; /* Ensure full opacity */
  font-size: 14px !important;
}