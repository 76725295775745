body::-webkit-scrollbar {
  display: none;
  // background: transparent;
}

.modal {
  // position: fixed;
  z-index: 100;
  inset: 0;
  // padding: 94px 56px;
  // background: rgba(0, 0, 0, 0.8);
  background: rgba(10, 10, 10, 1);
  // overflow: auto;
}

.playgroundModal {
  width: 100%;
  min-height: calc(100vh - 4px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 4px 4px 0px rgba(209, 28, 233, 1);
  // border-radius: 20px;
  position: relative;
}

@media (max-width: 640px) {
  .playgroundModal {
    box-shadow: unset;
    border: unset;
  }
}

.playgroundHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 2rem;
  height: 72px;
  // background: rgba(10, 10, 10, 1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  // border-top-left-radius: 20px;
  // border-top-right-radius: 20px;
}

.PlaygroundTitle {
  font-family: "Instrument Sans";
  font-size: 22px;
  font-weight: 400;
  line-height: 28.06px;
  color: rgba(198, 198, 198, 1);
}

.playgroundContainer {
  // display: flex;
  // flex-direction: row;
  // justify-items: space-between;
  // padding: 1rem;
  // gap: 2rem;
  // background: rgba(10, 10, 10, 1);
  position: relative;
}

.playgroundModalUploadContainer {
  width: 230px;
  height: fit-content;
  border-radius: 20px;
  // background: rgba(255, 255, 255, 0.02);
  padding: 1.25rem;
  border: 1px solid #FFFFFF0D;
}

.playgroundMenus {
  position: absolute;
  // z-index: 2;
  top: 1.25rem;
  left: 1.25rem;
  // display: flex;
  // gap: 0.75rem;
}

@media (max-width: 453px) {
  .playgroundMenus {
    left: 0;
  }

  .itemContainer {
    padding: 1rem !important;
  }
}

.playgroudMenuContainer {
  height: fit-content;
  // padding: 1.25rem 0;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // gap: 2.5rem;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.02);
}

// .playgroudContentContainer {
  // width: 100%;
  // margin-top: 133px;
  // margin-bottom: 196px;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // border-radius: 20px;
// }

// .editor-showing .playgroudContentContainer {
//   margin-top: 73px;
// }

.playgroudContentContainer > * {
  position: relative;
  z-index: 2;
}

.playgroudSizeContainer {
  height: 144px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #ffffff0d;
  padding: 1.25rem;
  gap: 1.5rem;
  background: #ffffff05;
  border-radius: 20px;
  color: white;
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  z-index: 3;
}

/* Tablet screens and smaller */
@media (max-width: 1024px) {
  .playgroundContainer {
    flex-direction: column;
    gap: 1rem;
  }
  // .playgroudContentContainer {
  //   width: auto;
  //   display: flex;
  //   margin-top: 0rem;
  //   gap: 0rem;
  // }
  // .playgroudMenuContainer {
  //   width: auto;
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   justify-content: space-between;
  //   margin-top: 0rem;
  //   height: auto;
  // }
}

.playgroundFooterContainer {
  width: 100%;
  position: absolute;
  bottom: 0;
  // z-index: 3;
  display: flex;
  align-items: flex-end;
  transition: all 0.3s ease-in-out;
}

.playgroundFooterContainer.is-collapsed {
  inset: unset;
  background: unset !important;
  backdrop-filter: unset !important;
  left: 0;
  bottom: 0;
}

.playgroundFooterContainer.fullscreen:not(.is-collapsed) {
  inset: 0;
  background: rgba(10, 10, 10, 0.1);
  backdrop-filter: blur(12px)
}

.playgroundFooterBarContainer {
  width: 100%; /* First div takes the full width */
  display: flex;  
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.playgroundFooterBar {
  height: 64px;
  color: #9d9d9d;
  width: 90%;
  border-radius: 20px;
  border: 1px solid #ffffff0d;
  background: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Instrument Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  gap: 0.5rem;
  cursor: pointer;
  //styleName: Instrument Sans/16/16px: SemiBold;
}

/* Mobile screens */
@media (max-width: 768px) {
  .playgroundContainer {
    padding: 0.5rem;
    gap: 1rem;
  }

  // .playgroudContentContainer {
  //   width: auto;
  //   display: flex;
  //   margin-top: 0rem;
  //   gap: 0rem;
  // }

  // .playgroudMenuContainer {
  //   width: auto;
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   justify-items: space-between;
  //   margin-top: 0rem;
  //   height: auto;
  // }
  // .playgroundModalUploadContainer {
  //   width: auto;
  // }
}

@media (max-width: 1200px) {
  .playgroundModalUploadContainer {
    width: auto;
  }
  .playgroundModalUploadContainer.text-generate-modal {
    width: 100%;
    max-width: 350px;
  }
}