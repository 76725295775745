@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Instrument Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: url("./assests/icons/cursor.svg"), auto;
  overflow-x: hidden;
  background: rgba(10, 10, 10, 1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p{
  margin: 0% !important;
  padding: 0% !important;
}

.relative {
  position: relative;
}

button {
  font-family: inherit;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0;
}

input, textarea {
  outline: none;
  border: none;
  background: transparent;
  font-family: inherit;
}