.editorContainer {
  background: #0f0f0f;
  border-top: 1px solid #ffffff1a;
  color: white;
  display: flex;
}

.code-container {
  width: 50%;
  padding: 1rem 2rem;
}

@media (max-width: 992px) {
  .editorContainer {
    flex-direction: column;
  }

  .code-container {
    width: 100%;
  }  
}

.editor-parent {
  height: 205px;
  transition: all 0.3s ease-in-out;
}

.playgroundFooterContainer.fullscreen .editor-parent {
  height: 490px;
}

.playgroundFooterContainer.mini:not(.is-collapsed), .playgroundFooterContainer.fullscreen:not(.is-collapsed) {
  top: 0;
  backdrop-filter: blur(20px);
} 

.editorHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  height: 36px;
}

.actionConatiner {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
}

.CSSPathContainer {
  border-left: 1px solid #ffffff1a;
}

.headerText {
  font-family: "Instrument Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 19.52px;
  color: rgba(198, 198, 198, 1);
}

.headerItem {
    height: 36px;
    width: 36px;
    border: 1px solid #FFFFFF1A;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
}

.actionSelectionContainer {
    border: 1px solid #FFFFFF1A;
    border-radius: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem 1rem;
    gap: 0.5rem;
    font-family: "Instrument Sans";
    font-size: 16px;
    font-weight: 500;
}

.code-block {
    color: #f8f8f2; /* Light text */
    font-family: "Instrument Sans";
    font-size: 14px;
    padding: 1rem;
    border-radius: 5px;
    line-height: 1.5;
    max-width: 400px;
    overflow-x: auto; 
  }
  
  .code-block p {
    margin: 0; /* Remove default paragraph margin */
    display: flex; /* Align number and code inline */
  }
  
  .line-number {
    display: inline-block;
    width: 20px;
    color: #888888; /* Grey color for line numbers */
    user-select: none; /* Prevent selection of line numbers */
  }
  