.mainContainer {
  // background: linear-gradient(45deg, #0a0010, #1a0024, #2e003e, #270d2f);
  background: #0D0011;
  // padding-bottom: 50px;
}

.container-2 {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 30px;
}

// Second container
.secondContainer {
  // background: radial-gradient(59.45% 59.45% at 50% 40.55%, #1F0A26 0%, rgba(31, 10, 38, 0) 100%);
  // background-image: url(../../assests/icons/grid.svg);
  // background-size: cover;
  // padding-bottom: 96px;
  border: "1px solid black";
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
}

.fourthContainer {
  padding-bottom: 96px;
}

.grid-illustration {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 1152px;
  height: 640px;
  z-index: 1;
  // background: radial-gradient(59.45% 59.45% at 50% 40.55%, #1F0A26 0%, rgba(31, 10, 38, 0) 100%);
}

.seoondHeader {
  border: 1px solid #FFFFFF33;
  border-radius: 100px;
  padding: 6px 10px;
  color: #bbbbbb;
  font-size: 14px;
  background: #FFFFFF0D;
  font-weight: 400;
  margin-bottom: 1.25rem;
  backdrop-filter: blur(4px)
}

.subtext-span {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 600;
  line-height: 59.99px;
}

.subtext-span.magic-span {
  font-size: 45px;
}

.secondSubHeaderContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.secondSubHeaderText {
  font-family: "Instrument Sans";
  font-size: 45px;
  font-weight: 500;
  line-height: 54.9px;
  background: linear-gradient(93.32deg, #FFFFFF 60.45%, #B4B4B4 99.76%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.secondSubHeaderContainer .emoji {
  font-family: Playfair Display;
  font-size: 60px;
  font-weight: 600;
  line-height: 80px;
}

.secondSubText {
  color: #7b7b7b;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 27px;
  font: "Instrument Sans";
  width: 100%;
  max-width: 475px;
  text-align: center;
}

.cta-button {
  box-shadow: 0px 16px 48px 20px #d323e71a;
  background: #d11ce9;
  padding: 12px 24px;
  border: 1px solid #d11ce9;
  border-radius: 100px;
  margin-top: 1.9rem;
  color: #fff;
  box-shadow: 0px 16px 48px 20px #D323E71A;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  height: 44px;
  position: relative;
  overflow: hidden;
}

// .cta-button {
//   position: relative;
//   display: inline-block;
//   padding: 12px 24px;
//   font-size: 1rem;
//   border: 2px solid #d11ce9;
//   border-radius: 20px; /* Match border-radius */
//   background: transparent;
//   color: #fff;
//   overflow: hidden;
//   cursor: pointer;
// }

/* Container to trace the border */
.streak-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100px; /* Match the button's border-radius */
  overflow: hidden;
  pointer-events: none; /* Prevent interaction */
}

/* Streak styles */
.streak {
  position: absolute;
  width: 50%; /* Streak size */
  height: 2px; /* Streak thickness */
  background: linear-gradient(to right, rgba(209, 28, 233, 1), transparent);
}

/* Top streak */
.streak-1 {
  top: 0;
  left: 0;
  animation: move-along-border 4s infinite ease-in-out;
}

/* Bottom streak */
.streak-2 {
  bottom: 0;
  right: 0;
  animation: move-along-border 4s infinite ease-in-out reverse;
}

/* Keyframes to animate along the border */
@keyframes move-along-border {
  0% {
    transform: translate(0%, 0%);
  }
  25% {
    transform: translate(100%, 0%); /* Top-right corner */
  }
  50% {
    transform: translate(100%, 100%); /* Bottom-right corner */
  }
  75% {
    transform: translate(0%, 100%); /* Bottom-left corner */
  }
  100% {
    transform: translate(0%, 0%); /* Return to start */
  }
}


.btn-highlight {
  flex: none;
  inset: 0;
  overflow: hidden;
  position: absolute;
  will-change: var(none, transform);
  z-index: 5;
  background: radial-gradient(29.2878% 47.1543% at 14.5193% 54.7808%, rgb(181, 225, 255) 0%, rgba(181, 225, 255, 0) 100%);
  border-radius: 100px;
  filter: blur(15px);
  opacity: 1;
  height: 46px;
  top: 0;
}

.cta-button .line-1 {
  position: absolute;
  top: -10px;
  left: -20px;
  z-index: 0;
}

.cta-button .line-2 {
  position: absolute;
  bottom: 0;
  right: 0;
}

.btn-subtext {
  font-style: italic;
  font-weight: 500;
}

// third container

.thirdContainer {
  color: white;
  width: 100%;
}

.thirdBoxContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.thirdIconBox {
  width: 56px;
  height: 56px;
  border: 1px solid #d11ce9;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.25rem;
  background: #d11ce91a;
}

.thirdTextBox {
  font-size: 20px;
  font-weight: 500;
  line-height: 24.4px;
  text-align: center;
}

.thirdSubTextBox {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #7b7b7b;
}

// fourth container

.ellipse-1 {
  width: 330px;
  height: 330px;
  border-radius: 100%;
  background-color: rgba(209, 28, 233, 0.5);
  filter: blur(170px);
  position: fixed;
  // top: 200px;
  // left: 0;
  top: 30%; left: 0;
  animation: rotateBG infinite 0.3s forwards;
}

.ellipse-2 {
  width: 330px;
  height: 330px;
  // top: 926px;
  // left: 1296px;
  background: rgba(209, 28, 233, 0.5);
  position: fixed;
  // top: 120px;
  // right: -80px;
  top: 50%; right: 0;
  filter: blur(170px);
  animation: rotateBG infinite 0.3s forwards;
}

@keyframes rotateBG {
  from {
    transform: rotate3d(150deg);
  }

  to {
    transform: rotate3d(360deg);
  }
}

.fourthSubHeader {
  font-family: "Instrument Sans";
  font-size: 32px;
  font-weight: 500;
  line-height: 39.04px;
  text-align: center;
  margin-bottom: 12px;
}

.fourthSubHeader .subtext-span {
  line-height: 42px;
}


.fourthSubText {
  font-family: "Instrument Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #7b7b7b;
}


.boxItemsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.5rem;
  // width: 100%;
  // max-width: 720px;
  // padding-left: 48px;
  position: relative;
}

.phase {
  width: 100%;
  max-width: 672px;
  // height: 144px;
}

.phase:last-child .timeline {
  background-color: transparent;
}

.boxItem {
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 12px;
}

.boxItemUnfilled {
  border: 1px solid #313131;
}

.boxItemFilled {
  border: 1px solid #d11ce9 !important;
  background: #d11ce933 !important;
}

.boxItemText {
  font-size: 20px;
  font-weight: 500;
  line-height: 24.4px;
  color: #ffffff;
}

.boxItemSubText {
  // font-size: 16px;
  // font-weight: 400;
  // line-height: 24px;
  color: #7b7b7b;
}


.timeline {
  position: absolute;
  left: 0; 
  height: 200px;
  width: 2px;
  background-color: rgba(49, 49, 49, 1); 
  margin-top: 1rem;
}

.circle {
  position: relative;
  // top: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  background: rgba(72, 72, 72, 0.3);
  border: 0.38px solid rgba(72, 72, 72, 1);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circle .inner-circle {
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
  background: rgba(72, 72, 72, 1);
}

.phaseFilled .circle {
  background: rgba(209, 28, 233, 0.3);
  border: 0.38px solid rgba(209, 28, 233, 1)
}

.phaseFilled .circle .inner-circle {
  background: rgba(209, 28, 233, 1);
}

/* Tablet screens and smaller */
@media (max-width: 1024px) {
  .secondSubHeaderContainer {
    gap: 5px;
  }

  .secondSubHeaderText, .subtext-span.magic-span {
    font-size: 42px;
  }

  .secondSubHeaderContainer .emoji {
    font-size: 40px;
  }

  .header-txt-img {
    width: 100px;
  }

  .fourthSubHeader {
    font-size: 28px;
  }
}

/* Mobile screens */
@media (max-width: 768px) {
  .secondSubHeaderContainer {
    gap: 2px;
  }
  
  .secondSubHeaderText, .subtext-span.magic-span {
    font-size: 36px;
    line-height: 50px;
  }

  .fourthSubHeader {
    font-size: 20px;
  }

  // .timeline {
  //   position: absolute;
  //   left: 10%;
  //   height: 350px;
  //   width: 2px;
  //   background-color: #d1d1d1; 
  // }
}

@media (max-width: 640px) {
  .secondSubHeaderText, .subtext-span.magic-span {
    font-size: 32px;
    line-height: 40px;
  }
}

@media (max-width: 576px) {
  .secondSubHeaderText, .subtext-span.magic-span {
    font-size: 29px;
    line-height: 40px;
  }

  .secondSubText {
    padding: 0 20px;
  }
}

@media (max-width: 470px) {
  .container-2 {
    padding: 0 20px;
  }

  .secondSubText {
    padding: 0 10px;
  }

  .seoondHeader {
    font-size: 12px;
  }

  .secondSubHeaderText, .subtext-span.magic-span {
    font-size: 27px;
    line-height: 40px;
  }

  .special-part {
    line-height: 50px;
  }

  .screen-text {
    display: inline-flex !important;
    align-items: center;
  }

  .bottom-subheader {
    display: none;
  }

  .secondSubHeaderContainer > img {
    display: none;
  }
}

.stars-illustration {
  position: absolute;
  top: 15px;
  left: 0;
}

.rays-illustration {
  position: absolute;
  top: 0;
  left: 0;
  // REMOVE
  z-index: 1;
  // opacity: 0.6;
  max-width: 100%;
}

.marketing-video {
  --video-border-radius: 16px
}

.marketing-video video {
  border-radius: 16px;
}