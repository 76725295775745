.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 1rem 1.25rem;
  height: 80px;
  border: 1px solid transparent;
  border-bottom: 1px solid;
  border-image-source: linear-gradient(89.98deg, rgba(255, 255, 255, 0) 0.01%, rgba(255, 255, 255, 0.5) 50.44%, rgba(255, 255, 255, 0) 99.99%);
  background: rgba(13, 0, 17, 0.3);
  backdrop-filter: blur(12px);
  border-image-slice: 0 0 1; /* Ensures the gradient is applied only on the bottom border */
  border-image-width: 1px; /* Sets the width of the border gradient */
  border-radius: 100px;
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 1136px;
}

.header {
  // padding: 3% 20%;
  // padding: 40px;
  display: flex;
  justify-content: center;
}

body:has(.header.playground-open) {
  overflow: hidden;
}

.headerLogo {
  font-family: "Playfair Display";
  padding: 0.5rem 1.25rem;
  // border: 1px solid;
  // border-image-source: linear-gradient(223.71deg, rgba(255, 255, 255, 0.5) 7.24%, rgba(255, 255, 255, 0.2) 24.31%, rgba(255, 255, 255, 0) 100%);
  // border-image-slice: 1; /* Ensures the gradient is applied only on the bottom border */
  // border-image-width: 1px;
  // border-radius: 12px;
  // max-width: 161px;
  // height: 48px;
  position: relative;
  // z-index: 2;
}

.headerLogo::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 12px;
  padding: 1px;
  mask: 
    linear-gradient(#000 0 0) content-box, 
    linear-gradient(#000 0 0);
  mask-composite: exclude; 
  background: linear-gradient(223.71deg, rgba(255, 255, 255, 0.5) 7.24%, rgba(255, 255, 255, 0.2) 24.31%, rgba(255, 255, 255, 0) 100%);
}

.buttonStyle {
  height: 44px;
  padding: 12px 24px;
  border-radius: 100px;
  border: 1px 0px 0px 0px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 16px 48px 20px rgba(211, 35, 231, 0.1);
  background: transparent;
  font-family: "Instrument Sans";
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 576px) {
  .buttonStyle {
    font-size: 14px;
  } 
}

.line-1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

footer .headerContainer {
  border-image-slice: 1 0 0;
}

.footerText {
font-family: "Instrument Sans";
text-align: center;
color: white;

}

// /* Tablet screens and smaller */
// @media (max-width: 1024px) {
//   .header {
//     padding: 2rem;
//   }
// }

// /* Mobile screens */
// @media (max-width: 768px) {

//   .header {
//     padding: 0.5rem;
//   }
// }